import Modal from "react-modal";
import ModalHeader from "../../modal-components/modal-header";
import ModalList from "../../modal-components/modal-list";
import assigneeData, { getAssigneeName } from "../../../assigneeData";
import ModalFooter from "../../modal-components/modal-footer";
import Button from "../../button";
import { useState } from "react";
import Tag from "../../tag";

const AssignProspectModal = ({setClosed, isOpen, assignee, setAssignee, shopName}) => {
  const [tempAssignee, setTempAssignee] = useState(null);

  const isMobile = window.innerWidth < 768;

  const handleClose = () => {
    setTempAssignee(null);
    setClosed();
  }

  const handleSubmit = () => {
    setAssignee(tempAssignee);
    setClosed();
  }

  const headerTags = () => (
    <>
      <Tag text={shopName} type="primary" size={isMobile ? 'medium' : 'large'} />
      {!isMobile && <Tag text={getAssigneeName(tempAssignee ?? assignee)?.toUpperCase() ?? ''} size='large' type="creme-brulee" />}
    </>
  )

  return (
    <Modal isOpen={isOpen} className="modal" overlayClassName="overlay">
      <ModalHeader title="Assign Prospect" setClosed={handleClose} tags={headerTags} />

      <div className="modal__content modal-scrollbar">
        <ModalList
          title="Field Team List"
          description="Which agent should pick this up?"
          options={assigneeData["Field Team"]}
          selectedOption={tempAssignee ?? parseInt(assignee)}
          onClick={setTempAssignee}/>
      </div>

      <ModalFooter>
        <Button
          buttonText="Confirm Selection"
          onClick={() => handleSubmit()}
          type="success"
          grow />
      </ModalFooter>
    </Modal>
  )
}

export default AssignProspectModal;