import React, { useEffect, useState } from 'react';
import './index.scss';
import MyRegion from "./boxes/my-region";
import {useSelector} from "react-redux";
import {Admin} from '../../fmo-api-sdk';
import Marketing from "./boxes/marketing";
import Orders from "./boxes/orders";
import Jobs from "./boxes/jobs";
import { getUserId } from '../../app/auth';

export default function Home() {
    const userData = useSelector((state: any) => state.auth.userData);
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)
    const [regionData, setRegionData] = useState<Array<Admin.AdminShop>>([]);
    const [jobData, setJobData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setLoadingJobs(true);

            const check = await Admin.isPermitted(userId);
            if (!check) return setLoadingJobs(false);

            Admin.getShopsManagedByAgent(userId)
                .then(apiData => {
                    // Build the data for this screen
                    if (apiData) {
                      for (const shop of apiData) {
                          // @ts-ignore
                          shop.orders.last_month_trend = shop.orders.last_month - shop.orders.prev_month;
                      }

                      // todo - this should be put in redux state, then referenced in each component
                      setRegionData(apiData);
                      setLoading(false);
                    }
                })
                .catch(res => {
                  console.log(`getShopsManagedByAgent ${res}`)
                  setLoadingJobs(false);
                });

            Admin.getAgentJobs()
                .then(apiData => {
                    // todo - this should be put in redux state, then referenced in each component
                    setJobData(apiData);
                    setLoadingJobs(false);
                })
                .catch(res => {
                  console.log(`getAgentJobs ${res}`);
                  setLoadingJobs(false);
                });
        })()
    }, [userId])

    return (
        <div id="homeWrapper">
            <MyRegion loading={loading} regionData={regionData}/>
            <Marketing loading={loading} regionData={regionData}/>
            <Jobs loading={loadingJobs} jobData={jobData}/>
            <Orders loading={loading} regionData={regionData} />
        </div>
    )
}


