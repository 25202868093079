import React, {useContext, useEffect, useState} from 'react';
import {unescape, filter, orderBy, get} from "lodash";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';
import PuffLoader from "react-spinners/PuffLoader";
import Disclaimer from "../../disclaimer/disclaimer";
import {Admin} from '../../../fmo-api-sdk';
import moment from "moment";
import NavigationContext from "../../../NavigationContext";
import AddJobModal from "../../modals/add-job";
import xLight from "../../../img/xLight.svg";
import addJobIcon from "../../../img/addJobIcon.svg";
import iconOrder from "../../../img/icons/order.svg";
import iconUser from "../../../img/icons/user.svg";
import Divider from "../../../img/divider.svg";
import useWindowSize from '../../../app/helper/screen';
import './index.scss';
import { getUserId } from '../../../app/auth';

function ShopPerformance({title = "Shop Performance"}) {

    const navigationContext = useContext(NavigationContext);
    const {searchTerm, searchType, updateSearch} = navigationContext;

    const history = useHistory();
    const navigate = url => history.push(url);
    const location = useLocation();
    const {state = {}} = location;
    const {agent = {}} = state as any;
    const token = useSelector((state: any) => state.auth.token);
    const userId = getUserId(token)

    const [shops, setShopsState] = useState<Array<Admin.AdminShop>>([]);
    const [filtered, setFiltered] = useState<Array<Admin.AdminShop>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sorter, setSorter] = useState('wholesaler_name');
    const [sortDir, setSortDir] = useState<string>("asc");
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [currentShop, setCurrentShop] = useState({shopId: '', shopName: ''});

    //render page title and hide it when there's an active filter on mobile
    const size = useWindowSize();
    const renderTitle = function () {
        if (size.width !== undefined && size.width >= 768) {
            return (<div className="title">{titleText}</div>);
        }
        if (searchTerm === "") {
            return (<div className="title">{titleText}</div>);
        }
        return null;
    }

    // Table sorting
    const sortTable = (head) => {
        let newSortDir = 'asc';
        if (sorter === head) {
            newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
        }

        setSortDir(newSortDir);
        setSorter(head);
    };

    // Initial data load
    useEffect(() => {
        (async () => {

            const check = await Admin.isPermitted(userId);
            if (!check) return setLoading(false);

            const agentToGet = agent.agent_id || userId;

            const data = await Admin.getShopPerformance(agentToGet)
              .catch(res => {
                console.log(res);
                setLoading(false);
              });

            // Set screen state
            setShopsState(data);

            // setFiltered(data);
            setLoading(false);
        })()
    }, [])

    // Filter data once loaded or filter changes
    useEffect(() => {
        let filtered = shops;

        if (searchTerm !== '') {
            if (searchType === 'name') {
                filtered = filter(shops, ob => ob.wholesaler_name.toLowerCase().includes(searchTerm.toLowerCase()));
            } else if (searchType === 'postcode') {
                filtered = filter(shops, ob => ob.wholesaler_postcode.toLowerCase().includes(searchTerm.toLowerCase()));
            }
        }

        setFiltered(filtered);
    }, [shops, searchType, searchTerm])


    const {Agent = ''} = agent;
    const agentText = Agent !== '' ? `Shops For ${Agent}` : '';
    const titleText = title || agentText || 'Agent Shops';

    // Sort the dataset
    const sorted = orderBy(filtered, [shop => {
        const value = get(shop, sorter, 0);

        // If the values are strings, make the sort case-insensitive
        if (typeof value === 'string') {
            return value.toLowerCase();
        }

        return value;
    }], [sortDir]);

    //prevent scrolling when modal is open
    const winddowWidth = window.innerWidth;
    const preventScrolling = function (showAddJobModal) {
        if (showAddJobModal) {
            document.body.classList.add("no-scroll");
        }
        else {
            document.body.classList.remove("no-scroll");
        }
    }

    preventScrolling(showAddJobModal);

    let nextMonth = {
      this_month: 'last_month',
      last_month: 'prev_month',
      prev_month: 'prev_prev_month',
      prev_prev_month: 'prev_prev_prev_month',
    };

    function getClass(sumValue, followingSumValue, trend?) {
      let givenClass = '';
      let diffPositive = sumValue > followingSumValue ? true : false;

      if (trend) {
        if (sumValue < 0) givenClass += 'td status-red';
        else givenClass += 'td status-green';
      } else {
        if (diffPositive) givenClass += 'status-green'
        else givenClass += 'status-red';
      }

      return givenClass;
    }

    function getSum(month, category, index) {
      let sumValue = sorted.reduce((acc, shop) => acc + shop[month][category], 0);
      let followingSumValue = sorted.reduce((acc, shop) => acc + shop[nextMonth[month]][category], 0);

      if (category !== 'trend') return <td key={index} className={getClass(sumValue, followingSumValue)}>{sumValue}</td>
      return (
        <td className='lead-column'>
          <div key={index} className={getClass(sumValue, followingSumValue, 'trend')}>
            {sumValue}
          </div>
        </td>
      );
    }

    const totalsTable = [
      {month: 'this_month', category: 'trend'},
      {month: 'this_month', category: 'sms'},
      {month: 'this_month', category: 'orders'},
      {month: 'this_month', category: 'customers'},
      {month: 'last_month', category: 'trend'},
      {month: 'last_month', category: 'sms'},
      {month: 'last_month', category: 'orders'},
      {month: 'last_month', category: 'customers'},
      {month: 'prev_month', category: 'trend'},
      {month: 'prev_month', category: 'sms'},
      {month: 'prev_month', category: 'orders'},
      {month: 'prev_month', category: 'customers'},
      {month: 'prev_prev_month', category: 'trend'},
      {month: 'prev_prev_month', category: 'sms'},
      {month: 'prev_prev_month', category: 'orders'},
      {month: 'prev_prev_month', category: 'customers'}
    ]

    const disallowedStatusCodes = [0, 5, 6, 7]

    return (
        <div id="shop-performance" style={showAddJobModal && winddowWidth > 1200 ? {right: 12} : {}}>
            <AddJobModal
                isOpen={showAddJobModal}
                data={{shopId: currentShop.shopId, shopName: currentShop.shopName}}
                setClosed={() => setShowAddJobModal(false)}
            />
            <div className="header">
                <div className="sticky-header">
                    {renderTitle()}
                    {searchTerm ? <div className="results-filter">
                        <div>
                            <div>{searchTerm}</div>
                        </div>
                        <div><img src={xLight} alt="x icon" onClick={() => updateSearch('', '')}/></div>
                    </div> : ''}
                </div>
                <Disclaimer />
            </div>
            {loading ?
                <div className="spinner-border">
                    <PuffLoader
                        size={75}
                        color={"#D82B31"}
                        loading={true}
                    />
                </div>
                :
                <div className="wrapper">
                    {sorted.length === 0 ? <div className="no-results">
                            <img src={Divider} alt="divider"/>
                            <div>No shops found.</div>
                        </div> :
                        <table className="table">
                            <thead>
                            <tr style={{zIndex: 3}}>
                                <th className="headcol sticky-row" style={{zIndex: 3}} rowSpan={2}>
                                    <div className="flex-basic">
                                        <div className="col-1">
                                            <div className="row-1">
                                                <span onClick={() => sortTable('wholesaler_name')}>Shop Name</span>
                                            </div>
                                            <div className="row-2">
                                                <span onClick={() => sortTable('wholesaler_id')}>ID</span>
                                                <span onClick={() => sortTable('wholesaler_postcode')} style={{
                                                    paddingLeft: 12
                                                }}>Postcode</span>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th className="sticky-row1" colSpan={4}>
                                    <div className="container">
                                        <div className="month">{moment().format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1" colSpan={4}>
                                    <div className="container">
                                        <div className="month">{moment().subtract(1, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1" colSpan={4}>
                                    <div className="container">
                                        <div className="month">{moment().subtract(2, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                                <th className="sticky-row1" colSpan={4}>
                                    <div className="container">
                                        <div className="month">{moment().subtract(3, 'M').format('MMM')}</div>
                                        <div className="line"/>
                                    </div>
                                </th>
                            </tr>
                            <tr style={{zIndex: 2}}>
                                <th className="lead-column sticky-row2" onClick={() => sortTable('this_month.trend')}>
                                    <div className="th">Trend</div>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('this_month.sms')}>
                                    SMS<br/>Sent
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('this_month.orders')}>
                                    Total <br/>
                                    <img src={iconOrder} alt="orders icon"/>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('this_month.customers')}>
                                    New <br/>
                                    <img src={iconUser} alt="user icon"/>
                                </th>

                                <th className="lead-column sticky-row2" onClick={() => sortTable('last_month.trend')}>
                                    <div className="th">Trend</div>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('last_month.sms')}>
                                    SMS<br/>Sent
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('last_month.orders')}>
                                    Total <br/>
                                    <img src={iconOrder} alt="orders icon"/>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('last_month.customers')}>
                                    New <br/>
                                    <img src={iconUser} alt="user icon"/>
                                </th>

                                <th className="lead-column sticky-row2" onClick={() => sortTable('prev_month.trend')}>
                                    <div className="th">Trend</div>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_month.sms')}>
                                    SMS<br/>Sent
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_month.orders')}>
                                    Total <br/>
                                    <img src={iconOrder} alt="orders icon"/>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_month.customers')}>
                                    New <br/>
                                    <img src={iconUser} alt="user icon"/>
                                </th>

                                <th className="lead-column sticky-row2"
                                    onClick={() => sortTable('prev_prev_month.trend')}>
                                    <div className="th">Trend</div>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_prev_month.sms')}>
                                    SMS<br/>Sent
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_prev_month.orders')}>
                                    Total <br/>
                                    <img src={iconOrder} alt="orders icon"/>
                                </th>
                                <th className="sticky-row2" onClick={() => sortTable('prev_prev_month.customers')}>
                                    New <br/>
                                    <img src={iconUser} alt="user icon"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                {sorted?.map((v, i) => {
                                    return (
                                        <tr key={i} className={disallowedStatusCodes.includes(parseInt(v.wholesaler_status)) ? "row" : undefined}>
                                            <td className="headcol" style={{zIndex: 2}}>
                                                <div className="flex-basic">
                                                    <div className="col-1">
                                                        <div className="row-1" style={{maxWidth: "200px", textOverflow: 'ellipsis', overflow: 'hidden'}} onClick={() => navigate(`/overview/${v.wholesaler_id}`)}>{unescape(v.wholesaler_name)}</div>
                                                        <div className="row-2">
                                                            <span>{v.wholesaler_id}</span>
                                                            <span style={{
                                                                paddingLeft: 12,
                                                                textTransform: 'uppercase'
                                                            }}>{v.wholesaler_postcode}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <img src={addJobIcon} alt="Create a job"
                                                            onClick={() => {
                                                                setCurrentShop({
                                                                    shopId: v.wholesaler_id,
                                                                    shopName: v.wholesaler_name,
                                                                });
                                                                setShowAddJobModal(true);
                                                            }}/>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="lead-column">
                                                <div
                                                    className={v.this_month.trend >= 0 ? 'td status-green' : 'td status-red'}>{v.this_month.trend}</div>
                                            </td>
                                            <td className={v.this_month.sms > v.last_month.sms ? 'status-green' : 'status-red'}>{v.this_month.sms}</td>
                                            <td className={v.this_month.orders > v.last_month.orders ? 'status-green' : 'status-red'}>{v.this_month.orders}</td>
                                            <td className={v.this_month.customers > v.last_month.customers ? 'status-green' : 'status-red'}>{v.this_month.customers}</td>

                                            <td className="lead-column">
                                                <div
                                                    className={v.last_month.trend >= 0 ? 'td status-green' : 'td status-red'}>{v.last_month.trend}</div>
                                            </td>
                                            <td className={v.last_month.sms > v.prev_month.sms ? 'status-green' : 'status-red'}>{v.last_month.sms}</td>
                                            <td className={v.last_month.orders > v.prev_month.orders ? 'status-green' : 'status-red'}>{v.last_month.orders}</td>
                                            <td className={v.last_month.customers > v.prev_month.customers ? 'status-green' : 'status-red'}>{v.last_month.customers}</td>

                                            <td className="lead-column">
                                                <div
                                                    className={v.prev_month.trend >= 0 ? 'td status-green' : 'td status-red'}>{v.prev_month.trend}</div>
                                            </td>
                                            <td className={v.prev_month.sms > v.prev_prev_month.sms ? 'status-green' : 'status-red'}>{v.prev_month.sms}</td>
                                            <td className={v.prev_month.orders > v.prev_prev_month.orders ? 'status-green' : 'status-red'}>{v.prev_month.orders}</td>
                                            <td className={v.prev_month.customers > v.prev_prev_month.customers ? 'status-green' : 'status-red'}>{v.prev_month.customers}</td>

                                            <td className="lead-column">
                                                <div
                                                    className={v.prev_prev_month.trend >= 0 ? 'td status-green' : 'td status-red'}>{v.prev_prev_month.trend}</div>
                                            </td>
                                            <td className={v.prev_prev_month.sms > v.prev_prev_prev_month.sms ? 'status-green' : 'status-red'}>{v.prev_prev_month.sms}</td>
                                            <td className={v.prev_prev_month.orders > v.prev_prev_prev_month.orders ? 'status-green' : 'status-red'}>{v.prev_prev_month.orders}</td>
                                            <td className={v.prev_prev_month.customers > v.prev_prev_prev_month.customers ? 'status-green' : 'status-red'}>{v.prev_prev_month.customers}</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td className="headcol headcol--totals">Totals</td>
                                    {totalsTable.map((sum, index) => {
                                        return (
                                            getSum(sum.month, sum.category, index)
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
            }
        </div>
    );
}

export default ShopPerformance;
